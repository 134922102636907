.warning {
  margin: 0 0 20px;
  padding: 30px 30px 30px 60px;
  background: #fff url(/_next/static/files/icon-warning_28e88a4d33f3581c84cafd0b43ed4e4e.svg) no-repeat 30px 28px;
  border-radius: 5px;
}
@media only screen and (max-width: 991px) {
  .warning {
    padding: 20px 20px 20px 50px;
  }
}
.warning a {
  color: #FE6054;
  text-decoration: underline;
}
.warning a:hover {
  text-decoration: none;
}
.warning.warning_without-title {
  background-position: 30px 50%;
}
@media only screen and (max-width: 991px) {
  .warning.warning_without-title {
    background-position: 15px 50%;
  }
}
.warning.warning_inline {
  height: 20px;
  line-height: 20px;
  padding: 0 0 0 30px;
  background-position: 0;
}
.warning__title {
  margin: 0 0 15px;
  font-size: 12px;
  font-weight: 800;
}
.warning__text {
  font-size: 12px;
  font-weight: 600;
  white-space: pre-line;
}
.warning p {
  margin: 0 0 10px;
}
