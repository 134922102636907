.rich-text-editor {
  position: relative;
  min-height: 155px;
  background-color: #F7F7F9;
  border: 1px solid transparent;
  border-radius: 5px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media only screen and (max-width: 991px) {
  .rich-text-editor {
    font-size: 14px;
  }
}
.rich-text-editor:hover {
  border: 1px solid #DCDCDC;
}
.rich-text-editor:focus,
.rich-text-editor:focus-within {
  background-color: #fff;
  border: 1px solid #9C9AA6;
}
.rich-text-editor .DraftEditor-root {
  margin: -3px 0 0;
  text-align: left;
}
.rich-text-editor .public-DraftEditorPlaceholder-root {
  position: absolute;
  padding: 15px;
  pointer-events: none;
}
.rich-text-editor .public-DraftEditorPlaceholder-inner {
  color: #bfbfbf;
}
.rich-text-editor .public-DraftEditor-content {
  min-height: 155px;
  padding: 15px;
}
