.page {
  padding: 45px;
  background: #fff;
  border-radius: 5px;
}
@media only screen and (max-width: 991px) {
  .page {
    padding: 30px 20px;
  }
}
.page__wrapper {
  width: 900px;
  margin: auto;
}
@media only screen and (max-width: 991px) {
  .page__wrapper {
    width: 100%;
  }
}
.page .page__title {
  margin: 0 0 40px;
  line-height: 1.22;
}
@media only screen and (max-width: 991px) {
  .page .page__title {
    margin: 0 0 30px;
  }
}
.page .page__title.page__title_margin-top {
  margin-top: 30px;
}
